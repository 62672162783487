import { Button, Card, Dialog } from "day8-ui";
import { InlineWidget } from 'react-calendly';
import Image from "next/image";
import { useState } from "react";

const Contact = ({ heading = "Talk to the team", subheading = "You've got questions, they've got answers. Jump on a call with our Yacht Week experts and let's get you on the water this summer.", buttonText = "Contact Us", hideSubheading = false }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className='dui-u-custom-theme-invert dui-container dui-mx-auto'>
            <Card className='dui-w-full dui-relative !dui-bg-primary-100 lg:dui-pl-14 dui-py-10 lg:dui-py-10'>
                <Image className="dui-z-0 dui-w-full dui-h-full dui-object-cover dui-absolute dui-left-0 dui-top-0" src="https://assets.theyachtweek.com/wp-content/uploads/2022/08/hero-banner-sign-up-2023-1.png" alt="sign up" width={1080} height={720} />
                <div className='dui-flex dui-z-10 dui-flex-col dui-flex-gap-8'>
                    <div className='dui-w-full dui-relative dui-text-center dui-text-white dui-max-w-lg dui-mx-auto'>
                        <h2 className="dui-text-6xl dui-mb-6 dui-font-heading !dui-font-normal dui-text-white dui-uppercase">{heading}</h2>
                        {!hideSubheading && <p className='lg:dui-text-lg dui-mb-8'>{subheading}</p>}
                        <Button onClick={() => setOpen(true)} className='dui-max-w-[300px] dui-font-subheading dui-uppercase dui-font-bold dui-min-w-[250px] dui-mx-auto dui-text-center'>{buttonText}</Button>
                        <Dialog
                            open={open}
                            dialogType="NORMAL"
                            scrollable
                            className="!dui-p-0"
                        >
                            <div className="dui-flex dui-gap-6 dui-pl-8 dui-pt-8 dui-overflow-x-auto dui-whitespace-nowrap dui-justify-start dui-items-center">
                                <a className="dui-cursor-pointer dui-font-subheading dui-flex dui-gap-1" onClick={() => setOpen(false)}>Back</a>
                            </div>
                            <InlineWidget
                                styles={{
                                    height: '720px'
                                }}
                                pageSettings={{
                                    backgroundColor: 'ffffff',
                                    hideEventTypeDetails: false,
                                    hideLandingPageDetails: true,
                                    primaryColor: '0284fe',
                                    textColor: '333'
                                }}
                                url="https://calendly.com/day8/15min"
                            />
                        </Dialog>
                    </div>
                </div>
            </Card>
        </div>
    )

}

export default Contact;
