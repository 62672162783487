import { CiCalendar } from "@react-icons/all-files/ci/CiCalendar";
import { CiGlobe } from "@react-icons/all-files/ci/CiGlobe";
import { Button } from "day8-ui";
import { useLuckyModal } from "../../context/LuckyModalContext";

const LuckyModalWidget = ({ className, title = "Create your perfect Yacht Week", description = "Stunning destinations. Exclusive parties. A lifetime of memories. Put ordinary vacations to shame in 2025." }: { className?: string, title?: string, description?: string }) => {
  const { setDefaultSection, toggle } = useLuckyModal();
  return (
    <div className={className}>
      <div className='dui-text-center'>
        <h2 className="dui-text-6xl dui-mb-6 dui-font-heading !dui-font-normal dui-text-primary-950 dui-uppercase">
          {title}
        </h2>
        <div className='dui-max-w-4xl  dui-mx-auto'>
          <p className="dui-mb-8  dui-text-primary-950 ">
            {description}
          </p>
        </div>
      </div>

      <div className='dui-shadow-xl dui-p-4 dui-flex dui-gap-3 dui-flex-col lg:dui-flex-row'>
        <Button buttonType='SECONDARY' className='dui-shadow-md dui-flex dui-w-full !dui-items-start !dui-justify-start !dui-py-3 !dui-px-3' onClick={() => { setDefaultSection('where'); toggle() }}>
          <CiGlobe className="dui-mr-2" />
          <div className='dui-text-left'>
            <p className='dui-font-subheading dui-font-bold dui-mb-1 dui-leading-4'>WHERE?</p>
            <p className='dui-mb-0 dui-mt-2 dui-font-body dui-font-normal dui-text-sm dui-text-primary-dark-400 dui-normal-case'>Pick a destination</p>
          </div>
        </Button>

        <Button buttonType='SECONDARY' className='dui-shadow-md dui-flex dui-w-full !dui-items-start !dui-justify-start !dui-py-3 !dui-px-3' onClick={() => { setDefaultSection('when'); toggle() }}>
          <CiCalendar className="dui-mr-2" />
          <div className='dui-text-left'>
            <p className='dui-font-subheading dui-font-bold dui-mb-1 dui-leading-4'>WHEN?</p>
            <p className='dui-mb-0 dui-mt-2 dui-font-body dui-font-normal dui-text-sm dui-text-primary-dark-400 dui-normal-case'>Add a date</p>
          </div>
        </Button>

        <Button buttonType='PRIMARY' className='xl:dui-min-w-[250px] dui-w-full dui-justify-center !dui-h-16 lg:!dui-h-auto !dui-py-2 !dui-px-3 dui-font-subheading dui-font-bold dui-uppercase' onClick={() => toggle()}>
          Search
        </Button>
      </div>
    </div>
  )
}

export default LuckyModalWidget;