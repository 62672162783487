import React from 'react';
import PageLayout from '../layouts/page';
import type { FrontPage } from '../pages';
import Image from 'next/image';
import { useLuckyModal } from '../context/LuckyModalContext';
import { Button, Card, Dialog, Hero, SnapCarousel } from 'day8-ui';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { RxCaretDown } from "@react-icons/all-files/rx/RxCaretDown";
import { IoPlayCircleOutline } from "@react-icons/all-files/io5/IoPlayCircleOutline";


import ReviewsSlider from './ReviewsSlider';
import SignUp5050 from './SignUp5050';
import Link from 'next/link';
import { StopElement } from './Product2025Wrapper';
import Spacer from "../components/Spacer"
import CountdownText from './CountdownText';
import moment from 'moment';
import useHash from '../hooks/useHash';
import TrustpilotWidget from './TrustpilotWidget';
import FestivalSlider, { FestivalCard } from './FestivalSlider';
import { getOriginalFestivals, getSpecialFestivals } from '../helpers/destination';
import Testimonial from './testimonial/Testimonial';
import Contact from './Contact';
import LuckyModalWidget from './lucky-week/LuckyModalWidget';
import { DestinationType } from '../globalTypes';

const reviews: StopElement[] = [
  {
    excerpt: {
      rendered: "Yacht Week, yes it's worth it! Each day is filled with endless fun, from lively parties to the thrilling regatta. We met amazing people from all over the world, creating memories that will last a lifetime."
    },
    title: {
      rendered: "Jack"
    }
  },
  {
    excerpt: {
      rendered: "Experience of a lifetime, beyond all expectations. It isn't just a vacation; it's a journey into the lap of luxury and adventure on the high seas. Yacht Week caters to all, offering a perfect blend of relaxation and adventure."
    },
    title: {
      rendered: "Dejan"
    }
  },
  {
    excerpt: {
      rendered: "Best bucket list thus far. Every day I woke up and could not believe the dream I was living. Best time of my life. From the themed parties, the amazing staff, the most delicious food to the well-coordinated itinerary. Just wow."
    },
    title: {
      rendered: "Marianne"
    }
  },
  {
    excerpt: {
      rendered: "From the beauty of the islands to the warmth of the sun and the energy of all involved it equated to a spectacular week. If you want the time of your life with great people and an amazing event crew, book Yacht Week."
    },
    title: {
      rendered: "Adam"
    }
  },
  {
    excerpt: {
      rendered: "After having my eye on Yacht Week trips for years, I was finally able to make it happen! From the day parties to the tunnel raft and everything in between, this trip was everything I thought it would be and more."
    },
    title: {
      rendered: "Raven"
    }
  },
  {
    excerpt: {
      rendered: "Yacht Week was literally the best week of my life. It was truly unique and \"is this real life\" type of experience that I am proud to have shared with the other amazing humans we met along the way."
    },
    title: {
      rendered: "Whitney"
    }
  }
]

const HomepageWrapper = ({ page, optionsPages, festivals }: FrontPage) => {
  const { toggle, setDefaultSection } = useLuckyModal();
  const [openModal, setOpenModal] = React.useState(false);
  const countdownDate = optionsPages.acf?.tyw_global_group?.countdown_banner || null;
  const campaignBanner = optionsPages.acf?.tyw_global_group?.campaign_banner || null;
  let isBookingOpen = true;
  if (countdownDate) {
    const parsedEndDate = moment.utc(countdownDate, "DD/MM/YYYY h:mm a");
    const now = moment.utc();
    const difference = parsedEndDate.diff(now);
    isBookingOpen = difference < 1;
  }
  useHash('#search', () => {
    setDefaultSection('where');
    toggle();
  });
  return (
    <div className="dui-bg-white dui-w-full block">
      <PageLayout page={page} canonical="https://www.theyachtweek.com/" signupHidden>
        <Hero
          video={{ url: 'https://cdn.theyachtweek.com/assets/video/2025/Homepage/Header/HomepageHeader1920x1080_720p.mp4' }}
          imageElement={<Image src="https://assets.theyachtweek.com/wp-content/uploads/2024/08/home-header.png" alt={"Yacht Week Presents"} width={1920} height={1080} />}
        >
          <Image src="https://assets.theyachtweek.com/wp-content/uploads/2024/08/ywpresents.png" alt="Yacht Week Presents" className='dui-my-4 dui-m-auto' width={280} height={63} />
          <div className='dui-container dui-mx-auto'>
            <h1 className="dui-text-[58px] md:dui-text-[70px] lg:dui-text-[100px] dui-uppercase dui-text-white">
              Sail and explore. <br />
              Dance and belong.
            </h1>
            {/* {isBookingOpen && <Button className='dui-mx-auto dui-mt-4' onClick={toggle}>Book now</Button>} */}
            {/* Navbar offset 80... */}
            <AnchorLink className="dui-flex dui-justify-center dui-animate-heroScroll dui-mt-4 md:dui-mt-8" href="#welcome" offset={80}>
              <RxCaretDown className='dui-text-white dui-text-4xl' />
            </AnchorLink>
            <div>
              <TrustpilotWidget className='dui-mt-4 md:dui-mt-8 dui-bg-transparent' theme='dark' template='mini' />
            </div>
          </div>
        </Hero>
        <div />
        {countdownDate && !campaignBanner && (
          <div className='dui-w-full dui-bg-primary-950 dui-p-4 dui-font-heading dui-text-white dui-text-center dui-uppercase'>
            <CountdownText endDate={countdownDate} />
          </div>
        )}

        <Spacer />

        <div id="welcome" />

        <div className="dui-container dui-mx-auto">
          <LuckyModalWidget title="Set sail on the best week of your life" description="Join us for seven days of sailing, exploring and dancing in the most enchanting corners of the world. Wake up to a new view everyday. Party your nights away. And leave with a community for life." />
        </div>

        <Spacer />

        <div className="dui-container dui-mx-auto">
          <Card className="!dui-p-0">
            <div className="dui-flex dui-flex-col md:dui-flex-row dui-w-full lg:dui-min-h-[400px]">
              <button className="dui-relative dui-w-full lg:dui-w-1/2 dui-relative" onClick={() => setOpenModal(true)}>
                <Image
                  alt="image"
                  className="dui-w-full dui-h-[400px] dui-min-h-full dui-object-cover"
                  src="https://assets.theyachtweek.com/wp-content/uploads/2025/01/homep_video_poster.png"
                  height={800}
                  width={800}
                />
                <IoPlayCircleOutline className="dui-absolute dui-top-1/2 dui-left-1/2 dui-translate-x-[-50%] dui-translate-y-[-50%] dui-text-white dui-text-6xl" />
              </button>
              <Dialog
                open={openModal}
                onOpenChange={() => setOpenModal(!openModal)}
                scrollable
                dialogType='FULLSCREEN'
                className='!dui-bg-black'
              >
                <div className='dui-relative dui-flex dui-flex-col dui-items-center dui-justify-center dui-h-full dui-gap-8'>
                  <iframe className="dui-w-full dui-h-full dui-max-h-[90%]" src="https://www.youtube.com/embed/Ng1gJTWHMig?si=ZKFywK-XOtWLU7FM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  <Button className='dui-mx-auto' buttonType='SECONDARY_2' onClick={() => setOpenModal(!openModal)}>Close</Button>
                </div>
              </Dialog>
              <div className="dui-w-full lg:dui-w-1/2 lg:dui-p-16 dui-p-4 dui-bg-secondary-100 dui-flex dui-flex-col dui-justify-center dui-text-primary-950">
                <h3 className="dui-text-5xl dui-font-bold dui-mb-8 dui-text-primary-950 dui-uppercase">
                  A trip like no other.
                </h3>
                <p className="dui-mb-8 ">
                  Each yacht is its own world—your space to eat, sleep, connect and unwind. Mornings start with a swim off the deck, afternoons take you to seaside restaurants, hidden caves and sun-drenched beaches. Evenings bring raft parties, waterfront clubs and live DJs.
                  No schedules, no stress. Just the open sea, good people and the best stories you’ll ever tell.
                </p>
              </div>
            </div>
          </Card>
        </div>

        <Spacer />

        <div className="dui-container dui-mx-auto">
          <div className='dui-text-center'>
            <h3 className="dui-text-6xl dui-mb-14 lg:dui-mb-8 dui-font-heading !dui-font-normal dui-text-primary-950 dui-uppercase">Explore originals</h3>
          </div>
          <div className='dui-grid dui-grid-cols-1 md:dui-grid-cols-2 dui-gap-8'>
            {/* only show main originals */}
            {getOriginalFestivals(festivals).filter((festival: DestinationType) => [1, 2, 5].includes(+festival.acf.destination_id)).map((festival: DestinationType, i: number) => (
              <FestivalCard className={`dui-col-span-1 ${i === 0 && 'md:dui-col-span-2'}`} item={festival} itemStyleType='PRIMARY_2' />
            ))}
          </div>
        </div>

        <Spacer />

        <FestivalSlider festivals={getSpecialFestivals(festivals)} title='Explore festivals' carouselSettings={{ autoplay: false }} />

        <Spacer />

        <div className='dui-overflow-hidden'>
          <SnapCarousel
            slides={[
              <Testimonial author='Forbes' quote='A cultural phenomenon that defies description.' />,
              <Testimonial author='Time Out' quote="You've never traveled like this before." />,
              <Testimonial author='Jetsetter' quote="A life-changing experience at sea." />,
              <Testimonial author='Cosmopolitan' quote='an absolute 10 out of mother-effing 10.' />,
            ]}
            settings={{
              infinite: true,
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 3000,
            }}
          />
        </div>

        <Spacer />

        <div className='dui-mx-auto dui-container'>
          <div className='dui-w-full dui-mx-auto dui-border-solid dui-border-primary-950 dui-border-2 dui-p-8'>
            <div className='dui-text-center dui-mb-0 dui-px-4'>
              <h3 className="dui-text-6xl dui-font-heading !dui-font-normal dui-mb-6 dui-text-primary-950 dui-uppercase">New to Yacht Week?</h3>
              <p className="dui-mb-8 lg:dui-mb-0 ">Gather your crew and set sail on the adventure of lifetime.</p>
            </div>
            <div className="dui-flex dui-flex-col lg:dui-flex-row ">
              <div className="dui-w-full dui-p-4 md:dui-py-10 md:dui-px-12 !dui-bg-transparent dui-flex dui-flex-col dui-justify-center dui-text-primary-950 dui-text-center">
                <h3 className="dui-text-4xl dui-font-semibold dui-mb-4 dui-text-primary-950 dui-uppercase">Pick a route</h3>
                <div className='dui-h-[2px] dui-w-full dui-mb-4 dui-mx-auto block dui-bg-primary-950'></div>
                <div className='dui-grow'>
                  <p className="dui-mb-4">Choose from island destinations and exclusive festivals on the water.</p>
                </div>
              </div>
              <div className="dui-w-full dui-p-4 md:dui-py-10 md:dui-px-12 !dui-bg-transparent dui-flex dui-flex-col dui-justify-center dui-text-primary-950 dui-text-center">
                <h3 className="dui-text-4xl dui-font-semibold dui-mb-4 dui-text-primary-950 dui-uppercase">Find a package</h3>
                <div className='dui-h-[2px] dui-w-full dui-mb-4 dui-mx-auto block dui-bg-primary-950'></div>
                <div className='dui-grow'>
                  <p className="dui-mb-4">Meet our range of majestic monohulls, classic catamarans - or go fully custom.</p>
                </div>
              </div>
              <div className="dui-w-full dui-p-4 md:dui-py-10 md:dui-px-12 !dui-bg-transparent dui-flex dui-flex-col dui-justify-center dui-text-primary-950 dui-text-center">
                <h3 className="dui-text-4xl dui-font-semibold dui-mb-4 dui-text-primary-950 dui-uppercase">PAY WITH EASE</h3>
                <div className='dui-h-[2px] dui-w-2/3 dui-mb-4 dui-mx-auto block dui-bg-primary-950'></div>
                <div className='dui-grow'>
                  <p className="dui-mb-8">Choose from a range of payment plans and split the costs with your crew.</p>
                </div>
              </div>
            </div>
            <div>
              <Link href="/how-it-works/step-by-step"><Button
                buttonType="PRIMARY"
                className="dui-self-center dui-mx-auto"
              >How it works</Button></Link>
            </div>
          </div>
        </div>

        <Spacer />

        <Contact />

        <Spacer />

        <ReviewsSlider reviews={reviews} />

        <SignUp5050 />
      </PageLayout >
    </div >
  );
};

export default HomepageWrapper;
